.bottleTabbar {
    width: 100%;
    height: var(--bottle-tabbar-height, 50px);
    display: flex;
    justify-content: space-around;
}

.bottleTabbarIconImage {
    width: 30px;
    height: 30px;
    margin-top: 3px;
}

.bottleTabbarIconName {
    color: #bfbfbf;
    font-size: 11px;
    text-align: center;
}

.bottleTabbarIconActive {
    color: #70ade1;
}
