.userInfoArea {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .avatar {
        width: var(--avatar-width, 50px);
        height: var(--avatar-height, 50px);
        border-radius: 50%;
        flex-shrink: 0;
    }
    .textArea {
        margin-left: var(--text-area-margin, 10px);
        .firstLine {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .userNick {
                font-size: var(--nick-font-size, 16px);
                font-weight: bold;
                margin-right: 5px;
            }
        }

        .descList {
            display: flex;
            flex-wrap: wrap;
            font-size: var(--desc-font-size, 12px);
            margin-top: var(--desc-margin-top, 3px);
            color: #88889a;
            .descItem {
                margin-right: 5px;
            }
        }
    }
}