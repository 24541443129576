.recordList {
    width: 100%;
    height: 100%;
}

.emptyDesc {
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
    color: grey;
}