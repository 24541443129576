
.blindBox {
    background: linear-gradient(to top left, #c0b572, #c9789b);
    min-height: 100vh;
    :global(.adm-tabs-header-mask) {
        background: transparent;
    }
}

.tabsStyle {
    :global(.adm-tabs-header) {
        border: none
    }
    :global(.adm-tabs-tab) {
        font-size: 16px;
        font-weight: 900;
    }
    :global(.adm-tabs-tab-active) {
        color: white;
    }
    :global(.adm-tabs-content) {
        padding-bottom: 0;
    }
    --active-line-color: white;
}

.swiperItemImage {
    width: 100%;
    border-radius: 10px;
    :global(.adm-image-tip) {
        min-height: 150px;
    }
}

.pageBodyWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5vh;
}

.bodyLeft {
    width: 46vw;
}

.bodyRight {
    width: 46vw;
}

.locationWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #efefef;
    width: fit-content;
    height: 30px;
    border-radius: 6px;
    padding: 0 10px;
}

.locationIcon {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    margin-right: 3px;
}

.cityName {
    font-size: 12px;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100px;
}

.closeCircleOutline {
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    margin-left: 5px;
}

.redirectImage {
    height: 6vh;
    border-radius: 6px;
    :global(.adm-image-tip) {
        min-height: 40px;
    }
}

.operateArea {
    margin-top: 1.5vh;
    padding-bottom: 20px;
    background-color: #656a81;
    border-radius: 6px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.personImageArea {
    width: 100%;
    :global(.adm-image-tip) {
        min-height: 130px;
    }
}

.buttonCommon {
    height: 7vh;
    line-height: 7vh;
    text-align: center;
    border-radius: 6px;
    color: white;
    font-size: 15px;
    font-weight: bold;
}

.buttonCommon:active {
    opacity: 0.8;
}


.saveNoteButton {
    margin-top: 10px;
    background: linear-gradient(to right, #fe8878, #feb57e);
    width: 90%;
}

.fetchNoteButton {
    margin-top: 10px;
    background: linear-gradient(to right, #c29a9a, #8694a3);
    width: 90%;
}

.heartGifImage {
    width: 16px;
    height: 16px;
}

.buttonList {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
}

.buttonItem {
    background: linear-gradient(to right, #c29a9a, #8694a3);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46%;
}

.gifImageBigButton {
    display: flex;
    align-items: center;
    justify-content: center;
}

.savePopupBody {
    padding: 15px 20px 3vh 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    :global(.adm-text-area-element) {
        font-size: 14px;
    }
    :global(.adm-image-uploader) {
        --cell-size: 24vw;
    }
    :global(.adm-button-primary) {
        background-color: #000000;
        border: none;
        border-radius: 10px;
        font-size: 14px;
        padding: 2vh 0;
    }
}

.popupBodyTitle {
    font-size: 15px;
    font-weight: bold;
}

.contentArea {
    box-sizing: border-box;
    width: 100%;
    margin: 2vh 0;
    border: 2px solid black;
    border-radius: 10px;
    padding: 10px;
    :global(.adm-divider) {
        margin: 10px 0;
    }
}

.scrollView {
    max-height: 40vh;
    overflow-y: scroll;
}

.uploadImageList {
    margin-top: 10px;
}

.wxInputWrapper {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-top: 1px solid #efefef;
    border-bottom: 1px solid #efefef;
}

.wxInputLeft {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-right: 5px;
    border-right: 1px solid #efefef;
    padding: 4vw 0;
    padding-right: 10px;
}

.wxInputHint {
    white-space: nowrap;
    font-size: 13px;
    font-weight: bold;
    margin-right: 5px;
}

.wxInputRight {
    :global(.adm-input-element) {
        font-size: 14px;
    }
    margin-left: 10px;
}

.priceDesc {
    font-size: 20px;
    color: #09bb07;
    font-weight: bold;
    margin-bottom: 2vh;
}

.displaySwitchWrapper {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.displaySwitchDesc {
    font-size: 13px;
    font-weight: bold;
}

.savePopupPersonImage {
    background-color: #656a81;
    width: 30vw;
    height: 40vw;
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.myNotePage {
    width: 100%;
    :global(.adm-list-body) {
        background-color: transparent;
        border: none
    }
    :global(.adm-list-item) {
        background-color: transparent;
        padding: 0;
    }
    :global(.adm-list-item-content) {
        padding: 0;
        border: none;
    }
    :global(.adm-list-item-content-main) {
        padding: 0;
    }
}

.noteSwitchTag {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 2px solid black;
    border-radius: 5px;
}

.noteSwitchTagItem {
    font-size: 14px;
    height: 5vh;
    line-height: 5vh;
    margin: 3px 0;
    width: 42vw;
    text-align: center;
    border-radius: 5px;
}

.noteList {
    overflow-y: scroll;
    :global(.adm-infinite-scroll) {
        color: white;
    }
    :global(.adm-notice-bar) {
        margin-top: 5px;
    }
}

.noteList::-webkit-scrollbar {
    display: none;
}

.activeNoteSwitchTagItem {
    color: white;
    background-color: black;
}

.emptyData {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60vh;
    font-size: 14px;
    color: white;
}

.emptyDataImage {
    width: 100px;
}

.emptyDataText {
    margin-top: 10px;
    margin-bottom: 5px;
}

.noteRecord {
    border: 2px solid black;
    border-radius: 5px;
    background-color: white;
    margin-top: 5px;
    padding: 10px;
    font-size: 14px;
    :global(.adm-ellipsis) {
        font-size: 14px;
    }
    :global(.adm-divider) {
        margin: 10px 0;
    }
    :global(.adm-image-uploader) {
        --cell-size: 24vw;
        --gap: 5px;
    }
}

.locationLine {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}

.ellipsisText {
    margin-bottom: 10px;
}

.wechatId {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.copyButton {
    margin-left: 10px;
    color: blue;
}

.operateButtonList {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.operateButton {
    margin-left: 10px;
    background-color: #00c853;
    color: white;
    padding: 5px 20px;
    border-radius: 5px;
}

.rejectButton {
    background-color: #ff976a;
}

.auditResult {
    margin-top: 5px;
    color: red;
}

.showOperateLine {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.extractCntDesc {
    color: #b5b5b5;
}

.destroyButton {
    width: 80px;
    background-color: black;
    color: white;
    text-align: center;
    padding: 5px 0;
    border-radius: 5px;
}

.locationLineLeft {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.noteRecordGender {
    margin-left: 10px;
    font-size: 14px;
    color: #fc5088;
}

.bottomButtonList {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 13px;
    font-weight: bold;
    padding: 10px 0;
    flex-wrap: wrap;
}

.verticalSeparator {
    width: 1px;
    background-color: white;
    height: 10px;
    margin: 0 5px;
}

.initExtractCntInput {
    :global(.adm-input) {
        width: 80px;
        border: 1px solid grey;
        border-radius: 5px;
    }
    :global(.adm-input-element) {
        font-size: 12px;
        text-align: center;
    }
    margin-left: 10px;
}

