.genderWrapper {
    width: 12px;
    height: 12px;
    background-color: #fdf1f5;
    border-radius: 50%;
    padding: 3px;
}

[data-prefers-color-scheme="dark"] .genderWrapper {
    background-color: #2c2c2c;
}