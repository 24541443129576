.rechargeCard {
    background-color: #f4f4f4;
    margin-top: 10px;
    margin-right: 10px;
    display: inline-block;
    border-radius: 5px;
}

.dark.rechargeCard {
    background-color: #2c2c2c;
}

.rechargeCardInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100px;
    height: 110px;
}

.rechargeCardTopLine {
    margin-top: 12px;
    display: flex;
    align-items: flex-end;
}

.rechargeCardTimeDesc {
    font-size: 24px;
    font-weight: 600;
}

.rechargeCardTimeUnit {
    font-size: 16px;
}

.rechargeCardPriceLine {
    margin: 8px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 19px;
}

.rechargeCardActive {
    color: #eabd38;
    border: 1px solid gold;
}

.rechargeCardDescLine {
    font-size: 13px;
}