.pageBody {
    overflow-y: scroll;
}

.pageBody::-webkit-scrollbar {
    display: none;
}

.promotion-page {
    padding: 10px;
}

.title {
    font-size: 18px;
    font-weight: bold;
    padding: 20px 0 10px 0;
}

.desc {
    font-size: 15px;
    color: #80848F;
    margin-bottom: 10px;
}

.empty-image-style {
    width: 200px;
    height: 200px;
    line-height: 200px;
    background-color: #f3f3f3;
    color: #80848F;
    margin: 0 auto;
    text-align: center;
    border-radius: 10px;
}

.qr-code-image {
    width: 200px;
    height: 200px;
    display: block;
    margin: 0 auto;
}

.redirect-url-style {
    padding: 10px;
    background-color: #2e2e2e;
    color: #f7a14b;
    border-radius: 10px;
    width: calc(100% - 20px);
    word-wrap: break-word;
}

.redirect-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-bottom: 40px;
}

.copy-url-button {
    margin-top: 10px;
    background-color: #07c160;
    color: white;
    width: 150px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 17px;
    border-radius: 25px;
}

.copy-url-button-hover {
    opacity: 0.6;
}

.poster-scroll-view {
    display: flex;
    white-space: nowrap;
}

.poster-image {
    display: inline-block;
    width: 250px;
    height: 400px;
    margin-right: 5px;
}