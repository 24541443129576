.pageBody {
    width: 100%;
    :global(.adm-button-large) {
        font-weight: 900;
        width: 98vw;
        margin: 2vw 0 0 1vw;
        border: none;
    }
}

.tabsStyle {
    :global(.adm-tabs-header) {
        border: none
    }
    :global(.adm-tabs-tab) {
        font-size: 20px;
        font-weight: 900;
    }
}

.memberCardWrapper {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 5vmin;
}

.memberDecWrapper {
    margin: 10px 0 10px 0;
}

.memberDescTitleLeft {
    display: inline-block;
    width: 5px;
    height: 18px;
    background-color: #eabd38;
    margin: 0 5px 0 10px;
}

.memberDescTitle {
    display: inline-block;
    text-align: left;
    font-size: 18px;
    color: #eabd38;
    font-weight: 600;
    vertical-align: 2px;
}

.memberDescTextWrapper {
    margin-bottom: 5px;
}

.smallPoint {
    display: inline-block;
    margin-left: 10px;
    width: 2vmin;
    height: 2vmin;
    border-radius: 50%;
    background-color: #eabd38;
    vertical-align: 3px;
}

.memberDescText {
    display: inline-block;
    text-align: left;
    margin-left: 5px;
    font-size: 15px;
    font-weight: 500;
}

.openButton {
    --adm-font-size-10: 20px;
    --background-color: #eabd38 !important;
    --border-radius: 25px !important;
}

.noticeDesc {
    text-align: center;
    font-size: 13px;
    opacity: 0.8;
    margin-top: 5px;
}

.userInfoHintMsg {
    margin-left: 5px;
    margin-top: 10px;
    opacity: 0.8;
}