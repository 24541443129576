
.joinPartnerPage {
    overflow-y: scroll;
    background-color: white;
    border-radius: 10px;
}

.joinPartnerPage::-webkit-scrollbar {
    display: none;
}

.contentWrapper {
    padding: 10px;
}

.contentText {
    font-size: 15px;
    margin-bottom: 5px;
}

.contentTitle {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 10px;
}

.contentImageWrapper {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-around;
}

.incomeImage {
    width: 42vw;
    margin-right: 5px;
    border: 1px solid grey;
}

.contactWrapper {
    padding: 0 10px 0 10px;
    :global(.adm-input) {
        width: calc(100% - 12px);
    }
}

.contactInput {
    --font-size: 17px;
    border: 1px solid grey;
    padding: 5px 0 5px 10px;
    border-radius: 10px;
}

.joinButton {
    padding: 14px 10px 50px 10px;
    :global(.adm-button) {
        background-color: black;
        color: white;
        font-size: 17px;
        font-weight: bold;
        text-align: center;
        border: none;
        border-radius: 10px;
        width: 49%;
    }
    display: flex;
    justify-content: space-between;
}

.boldText {
    font-weight: bold;
}

.partnerTypeImage {
    margin-bottom: 10px;
}