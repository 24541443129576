.bottleHomepage {
    --bottle-tabbar-height: 50px;
}

.bottleHomepageBg {
    width: 100%;
    height: calc(100vh - 50px - 20px);
    background-image: url("../../images/bg.png");
    background-size: cover;
}

.filterButton {
    position: absolute;
    left: 3vmin;
    top: 12vmin;
    display: flex;
    align-items: center;
}

.filterImageStyle {
    width: 5vmin;
    height: 5vmin;
}

.filterDescStyle {
    font-size: 16px;
    color: #4f61c6;
    font-weight: 500;
}

.operatePlatform {
    position: absolute;
    bottom: 20vh;
    left: 10vw;
    width: 80vw;
    border-bottom: 50px solid rgba(0, 0, 0, 0.2);
    border-left: 40px solid transparent;
    border-right: 40px solid transparent;
    box-sizing: border-box;
}

.operateButtons {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    bottom: 20vh;
}

.operateButtonMarginLeft {
    margin-left: 15vmin;
}

.operateButton {
    position: relative;
}

.operateButtonIcon {
    width: 16vmin;
    height: 16vmin;
    background-color: rgba(255,255,255,0.6);
    text-align: center;
    border-radius: 10px;
}

.operateButtonIconImage {
    display: inline-block;
    width: 15vmin;
    height: 15vmin;
}

.operateButtonIconText {
    color: white;
    text-align: center;
}

.redPointDesc {
    width: 20px;
    height: 20px;
    background-color: red;
    color: white;
    font-size: 16px;
    text-align: center;
    border-radius: 50%;
    position: absolute;
    top: -10px;
    right: -10px;
}

.textAreaStyle {
    margin-left: 5px;
}

.popupStyle {
    position: relative;
}

.sendButton {
    position: absolute;
    bottom: 0;
}

.beianDesc {
    text-align: center;
}
