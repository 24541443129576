
.popupBody {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.title {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
}

.subTitle {
    margin-top: 10px;
    font-size: 14px;
}