.bottleShowPopupMask {
    --z-index: 100;
}

.bottleShowPopup {
    width: 80%;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;

    .userInfoLine {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .reportButton {
            color: #88889a;
            font-size: 14px;
            flex-shrink: 0;
        }
    }

    .textContent {
        width: 100%;
        margin-top: 10px;
        font-size: 16px;
        max-height: 105px;
        overflow-y: scroll;
    }

    .textArea {
        box-sizing: border-box;
        width: 100%;
        background-color: #f4f4fc;
        margin-top: 10px;
        border-radius: 10px;
        padding: 8px;
        :global(.adm-text-area-element) {
            --font-size: 16px;
        }
    }

    .buttonList {
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .cancelButton {
            --border-radius: 20px;
            width: 80px;
            font-size: 16px;
            background-color: rgba(128, 128, 128, 0.1);
        }

        .replyButton {
            --border-radius: 20px;
            width: 200px;
            font-size: 16px;
            background-color: #6c6cf4;
            color: white;
            font-weight: bold;
        }
    }
}

[data-prefers-color-scheme="dark"] .bottleShowPopup {
    background-color: #191919;
    .reportButton {
        color: #4c4c4c;
    }
    .textArea {
        background-color: #2c2c2c;
    }
}
