.withdrawApplyPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
}

.hintText {
    color: #80848F;
    font-size: 14px;
    margin-top: 10px;
    font-weight: 500;
}

.inputItem {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 20px 0;
}

.inputItemTitle {
    width: 100px;
    font-size: 18px;
    font-weight: 500;
}

.divideLine {
    width: 100%;
    height: 1px;
    background-color: rgba(128, 128, 128, 0.3);
}

.imageItem {
    width: 100%;
    padding: 20px 0;
}

.imageItem .inputItemTitle {
    margin-bottom: 14px;
}

.reminderDoc {
    font-size: 14px;
    color: #80848F;
}

.reminderDocTitle {
    font-size: 18px;
    font-weight: 500;
    color: black;
}

.reminderDocText {
    margin-top: 10px;
    font-weight: 500;
}

.submitButton {
    margin-top: 30px;
}