.memberCard {
    width: 23vmin;
    height: 30vmin;
    background-color: #f4f4f4;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-radius: 5px;
    text-align: center;
    overflow: hidden;
    border: 1px solid #f4f4f4;
    font-weight: 500;
    padding-bottom: 2vmin;
}

.memberCard.dark {
    border: none;
}

.memberCardActive {
    color: #eabd38;
    border: 1px solid gold;
}

.memberCardActive.dark {
    color: #eabd38;
    border: 1px solid gold;
}

.timeWrapper {
    display: block;
    margin-top: 1vmin;
}

.time {
    font-size: 26px;
}

.timeDesc {
    font-size: 15px;
}

.amountDesc {
    font-size: 20px;
}

.bottomDesc {
    font-size: 11px;
    opacity: 0.6;
    margin-top: 5px;
}

.dark {
    background-color: #2c2c2c;
    color: white;
}

