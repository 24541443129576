
.title {
    text-align: center;
}

h1{
    margin-bottom: 10px;
}

.title div {
    color: #757575;
    font-size: 14px;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}
.documentItem {
    width: 50%;
    margin-top: 50px;
}
.contentItem {
    border-left: 3px solid #21759b;
    padding-left: 10px;
    margin-bottom: 20px;
}
.contentItem div{
    color: #21759b;
    display: inline-block;
    font-size: 20px;
    text-decoration: none;
}

.contentOverview {
    font-size: 18px;
}

.beian {
    position: absolute;
    bottom: 20px;
    left: 45%;
    font-size: 18px;
}

.overlayStyle {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popupStyle {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    width: 40%;
    height: 80%;
}

.closeButtonStyle {
    position: absolute;
    top: 0px;
    right: 0px;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: blue;
}