.inputAreaPopup {
    width: 80%;
    min-height: 200px;
    background-color: white;
    color: black;
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .title {
        font-size: 18px;
        font-weight: bold;
        margin: 10px 0;
    }

    .inputArea {
        width: 80%;
        height: 120px;
        border: 2px solid #3c74f4;
        border-radius: 10px;
        padding: 10px;
        :global(.adm-text-area) {
            --font-size: 16px;
        }
    }

    .buttonList {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        :global(.adm-button) {
            width: 100px;
            font-size: 16px;
            --border-radius: 20px;
        }
    }

    .cancelButton {
        --background-color: #ececec;
    }

    .confirmButton {
        margin: 10px 0 10px 30px;
        
    }
}

[data-prefers-color-scheme="dark"] .inputAreaPopup {
    background-color: #191919;
    color: white;
}

[data-prefers-color-scheme="dark"] .cancelButton:global(.adm-button) {
    background-color: #2c2c2c !important;
}