.inputPopupBody {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inputPopupTitle {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 600;
}

.inputPopupSubTitle {
    font-size: 12px;
    margin-bottom: 10px;
}

.inputBox {
    width: 70% !important;
    height: 40px;
    border: 2px solid #4984f7;
    border-radius: 10px;
}

.submitButton {
    width: 50% !important;
    border-radius: 10px !important;
    margin-top: 10px !important;
    margin-bottom: 20px !important;
    height: 44px !important;
}