.userInfoCard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f7f7f7;
    border-radius: 10px;
    margin: var(--user-info-card-margin, 5px);
}

.dark.userInfoCard {
    background-color: #2c2c2c;
}

.leftPart {
    display: flex;
    align-items: center;
}

.avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.userNickAndIdBlock {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
}

.userNickLine {
    display: flex;
    align-items: center;
}

.userNick {
    font-size: 20px;
    font-weight: 600;
}

.genderIcon {
    width: 18px;
    height: 18px;
}

.userIdLine {
    font-size: 14px;
    margin-top: 5px;
    opacity: 0.8;
}

.buttonStyle {
    font-size: 14px;
    border-radius: 20px;
}