.bottleBgImage {
    width: 100%;
    position: absolute;
    z-index: -100;
}

.topLine {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5px 0 10px;
}

.filterArea {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.filterIcon {
    width: 24px;
    height: 24px;
}

.filterText {
    font-size: 16px;
    color: white;
    margin-left: 5px;
}

.goldCoinArea {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(140, 156, 172, 0.7);
    padding: 5px 10px;
    border-radius: 20px;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    perspective: 1000px;
}

.goldCoinAreaDark {
    background-color: rgba(140, 156, 172, 0.2);
}

.goldCoinIcon {
    width: 18px;
    height: 18px;
    transform-style: preserve-3d; /* 保留 3D 变换效果 */
    animation: rotateY 1s ease-out 200ms 1; /* 5 秒内旋转一周，循环进行 */
}

@keyframes rotateY {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(360deg);
    }
}

.goldCoinNumber {
    font-size: 14px;
    color: white;
    margin-left: 5px;
}

.myBottleButton {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 16px;
    color: white;
}

.arrowRightIcon {
    width: 20px;
    height: 20px;
}

.operateArea {
    height: 0;
    border-bottom: 45px solid rgba(0,0,0,0.2);
    border-left: 42px solid transparent;
    border-right: 42px solid transparent;
    position: absolute;
    width: calc(100% - 84px - 60px);
    left: 30px;
    bottom: 90px;
}

.operateButtonLine {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 94px;
}

.operateButton {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.operateButton:active {
    opacity: 0.7;
}

.salvageButton {
    margin-left: 70px;
}

.buttonImage {
    width: 60px;
    height: 60px;
    border-radius: 5px;
    background-color: rgba(255,255,255,0.6);
    display: flex;
    align-items: center;
    justify-content: center;
}

.buttonImageDark {
    background-color: rgba(255,255,255,0.4);
}

.buttonImageIcon {
    width: 61px;
    height: 60px;
}

.buttonText {
    font-size: 15px;
    color: white;
    margin-top: 2px;
}

.bottomHint {
    font-size: 12px;
    color: rgba(128, 128, 128, 0.8);
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 5px;
}

.boatIcon {
    width: 45px;
    height: 40px;
    position: absolute;
    top: 35%;
    z-index: 100;
    animation: boatMove 40s linear infinite;
}

@keyframes boatMove {
    0% {
        left: 0px;
    }
    50% {
        left: 200px;
    }
    100% {
        left: 0px;
    }
}

.balloonIcon {
    width: 32px;
    height: 40px;
    position: absolute;
    top: 28%;
    left: 50%;
    animation: balloonMove 60s linear infinite;
}

@keyframes balloonMove {
    0% {
        top: 28%;
        left: 50%;
    }
    25% {
        top: 16%;
        left: 0%;
    }
    50% {
        top: 6%;
        left: 50%;
    }
    75% {
        top: 16%;
        left: 90%;
    }
    100% {
        top: 28%;
        left: 50%;
    }
}

.lampIconWrapper {
    height: 52%;
    width: 60%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
}

.lampIcon {
    height: 100%;
    width: 100%;
}

.maskNetIconWrapper {
    width: 100%;
    height: 150px;
    position: absolute;
    z-index: 200;
    bottom: 10%;
    overflow: hidden;
}

.maskNetIcon {
    width: 90px;
    height: 80px;
    position: absolute;
    top: 40%;
    right: 0;
    transform: rotate(-40deg);
    animation: netMove 2s linear forwards;
}

@keyframes netMove {
    0% {
        top: 40%;
        right: 0;
        transform: rotate(-40deg);
    }

    30% {
        top: 75%;
        right: 0;
        transform: rotate(-20deg);
    }

    50% {
        top: 80%;
        right: 25%;
        transform: rotate(-20deg);
    }

    70% {
        top: 75%;
        right: 50%;
        transform: rotate(20deg);
    }

    100% {
        top: 40%;
        right: 28%;
        transform: rotate(40deg);
    }
}

.maskBottleWrapper {
    width: 40px;
    height: 28px;
    overflow: hidden;
    transform: rotate(-40deg);
    position: absolute;
    top: 27%;
    left: 7%;
    opacity: 0;
    animation: maskBottleVisiable 2s linear forwards;
}

.maskBottle {
    width: 40px;
    height: 40px;
    transform: rotate(-56deg);
}

@keyframes maskBottleVisiable {
    0% {
        opacity: 0;
    }
    69% {
        opacity: 0;
    }
    70% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

.filterPanel {
    width: 80%;
    background-color: white;
    border-radius: 10px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

[data-prefers-color-scheme="dark"] .filterPanel {
    background-color: #191919;
    color: white;
}

.filterPanel .title {
    font-size: 18px;
    font-weight: bold;
    margin: 15px 0;
}

.filterPanel .divider {
    width: 90%;
    height: 1px;
    background-color: rgba(128, 128, 128, 0.2);
}

.filterPanel .filterCell {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
    font-size: 14px;
}

.filterPanel .genderItemList {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.filterPanel .cellTitle {
    font-size: 14px;
    font-weight: bold;
}

.genderItem {
    background-color: #ececec;
    padding: 5px 15px;
}

[data-prefers-color-scheme="dark"] .genderItem {
    background-color: #2c2c2c;
    color: white;
}

.genderItemActive {
    background-color: black !important;
    color: white !important;
}

.leftGenderItem {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.rightGenderItem {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

.buttonItemList {
    margin: 20px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonItem {
    font-size: 16px;
    padding: 10px 40px;
    background-color: #ececec;
    --border-radius: 20px;
}

[data-prefers-color-scheme="dark"] .buttonItem {
    background-color: #2c2c2c;
    color: white;
}

.confirmButton {
    background-color: #f4cc4c !important;
    margin-left: 8%;
}

.consumeDesc {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(30%, -50%);
    background-color: #ec645c;
    color: white;
    padding: 2px 5px;
    border-radius: 20px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.darkConsumeDesc {
    background-color: rgba(0, 0, 0, 0.4);
}

.consumeCoinIcon {
    width: 14px;
    height: 14px;
    margin-right: 1px;
    animation: roteateYInterval 10s ease-out 200ms infinite;
}

@keyframes roteateYInterval {
    0% {
        transform: rotateY(0deg);
    }
    10% {
        transform: rotateY(360deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}

.lampIconPreLoad {
    display: none;
}

.frontVersion {
    position: absolute;
    bottom: 5px;
    right: 5px;
    color: rgba(128, 128, 128, 0.8);
    font-size: 12px;
}

[data-prefers-color-scheme="dark"] .vantDialog {
    --rv-dialog-background-color: #191919;
    --rv-button-default-background-color: #2c2c2c;
    :global(.rv-hairline--top::after) {
        border: none;
    }
}
