.pageBody {
    :global(.adm-button-large) {
        font-weight: 900;
        width: 98vw;
        margin: 3vw 0 0 1vw;
        border: none;
    }

    --user-info-card-margin: 10px;
}

.rechargeCoinDesc {
    font-size: 18px;
    font-weight: 600;
    margin-top: 10px;
    margin-left: 10px;
    display: flex;
    align-items: flex-end;
}

.rechargeAreaGap {
    margin-top: 20px;
}

.rechargeCardList {
    margin-left: 10px;
    display: flex;
    justify-content: flex-start;
    overflow-x: scroll;
    white-space: nowrap;
}

.rechargeCardList::-webkit-scrollbar {
    display: none;
}

.openButton {
    --adm-font-size-10: 20px;
    --background-color: #eabd38 !important;
    --border-radius: 25px !important;
}

.openMemberButton {
    --adm-font-size-10: 20px;
    --background-color: #717bf7 !important;
    --border-radius: 25px !important;
}


.noticeDesc {
    text-align: center;
    font-size: 13px;
    opacity: 0.8;
    margin-top: 5px;
}


.userInfoHintMsg {
    margin-left: 10px;
    margin-top: 10px;
    opacity: 0.8;
}

.rechargeCoinSubDesc {
    font-size: 16px;
    color: #979ba2;
}

.memberDescArea {
    margin-left: 10px;
}

.memberDecWrapper {
    margin: 5px 0 5px 0;
    display: flex;
    align-items: center;
}

.memberDescTitleLeft {
    width: 5px;
    height: 18px;
    background-color: #eabd38;
    margin-right: 5px;
}

.memberDescTitle {
    text-align: left;
    font-size: 18px;
    color: #eabd38;
    font-weight: 600;
    vertical-align: 3px;
}

.memberDescItem {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 4px;
}

.smallPoint {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #eabd38;
}

.memberDescText {
    text-align: left;
    margin-left: 5px;
    font-size: 15px;
    font-weight: 500;
}