
.withdrawAuditCard {
    font-size: 16px;
}

.cardItem {
    display: flex;
    align-items: flex-start;
    
}

.cardItemTitle {
    font-weight: 500;
    flex-shrink: 0;
}

.cardItemQrCode {
    width: 100px;
    height: 100px;
}

.operateArea {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.inputBox {
    width: 140px;
    border: 1px solid blue;
    border-radius: 5px;
    padding: 2px 6px;
}

.operateRight {
    display: flex;
    width: 180px;
    justify-content: space-around;
}

.operateButton {
    width: 80px;
}

.cardItemStatus {
    color: #00c853;
}