
.bottleShowCard {
    .userInfoArea {
        --avatar-width: 40px;
        --avatar-height: 40px;
        --nick-font-size: 14px;
        --desc-font-size: 12px;
        --text-area-margin: 5px;
        --desc-margin-top: 1px;
    }
    .ellipsisText {
        margin-top: 5px;
        font-size: 16px;
    }
    .bottomLine {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .timeDesc {
            font-size: 14px;
            color: #88889a;
        }
        .buttonList {
            margin-top: 5px;
        }
        .buttonItem {
            margin-left: 10px;
            font-size: 13px;
            --adm-button-border-radius: 20px;
        }
        .deleteButton {
            color: #D9534F;
            background-color: rgba(128, 128, 128, 0.1);
        }
        .detailButton {
            background-color: #6c6cf4;
            color: white;
        }
    }
}

