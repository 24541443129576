
.agreementPopupBody {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
}

.title {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
}

.contentArea {
    width: 100%;
    height: 50vh;
    overflow-y: scroll;
}

.contentText {
    margin-top: 10px;
    text-align: left;
    color: black;
}

.buttonArea {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.checkBox {
    font-weight: bold;
    margin: 10px 0;
}

.button {
    background-color: black;
    width: 100%;
    height: 7vh;
    line-height: 7vh;
    text-align: center;
    color: white;
    font-size: 16px;
    font-weight: bold;
    border-radius: 10px;
}