.partner-manage {
    position: relative;
}

.partner-manage-bg {
    width: 100%;
    height: 32vh;
    z-index: -10;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.withdrawal-amount {
    position: absolute;
    top: 5vh;
    width: 100%;
    text-align: center;
    color: white;
}

.withdrawal-amount-title {
    font-size: 15px;
    margin-bottom: 5px;
}

.withdrawal-amount-value {
    font-size: 50px;
    font-weight: 500;
}

.amount-item-list {
    display: flex;
    justify-content: space-around;
    width: 100%;
    position: absolute;
    top: 20vh;
}

.amount-item {
    color: white;
    text-align: center;
    width: 33vw;
}

.amout-item-title {
    font-size: 15px;
    margin-bottom: 8px;
}

.amout-item-value {
    font-size: 25px;
    font-weight: 500;
}

.divide-line {
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.cell-area {
    background-color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.cell-item-list {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.cell-item {
    text-align: center;
    width: 31vw;
    height: 26vw;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cell-item:active {
    background-color: #f3f3f3;
}

.cell-item-hover {
    background-color: #f3f3f3;
}

.cell-item-icon {
    width: 30px;
    height: 30px;
    margin-bottom: 10px;
}

.shouyi-mingxi-icon {
    width: 29px;
    height: 29px;
}

.tixian-mingxi-icon {
    width: 32px;
    height: 32px;
}